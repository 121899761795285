$(document).ready(() => {
    let yMap, yManager, yRoute;
    let selectAddress = $('#select-address');

    $('#calculate').on('click', () => {
        let button = $(this);
        let originalCaption = button.text();
        let calculateUrl = $('#form').data('url');
        let address = $('#select-address');

        $(this).text('Идет расчет...').attr('disabled', 'disabled');
        $('.error-message').text('');
        $.ajax(calculateUrl, {
            type: "POST",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {
                nds: $('input[name ="nds"]:checked').val(),
                size: $('input[name ="size"]:checked').val(),
                weight: $('input[name ="weight"]').val(),
                staging_terminal_id: $('#staging-terminal').val(),
                terminal_for_empty_id: $('#terminal-for-empty').val(),
                forwarder: $('input[name ="forwarder"]:checked').val(),
                unloading_address: address.val(),
                unloading_address_coordinates: address.attr('data-lat') + ',' + address.attr('data-lng')
            },
            cache: false,
            success: function (data) {
                showRoute([window.point1, window.point2, window.point3]);
                button.text(originalCaption).removeAttr('disabled');
                $("#result").html(data);
            },
            error: function (data) {
                let errors = data.responseJSON.errors;
                for (let key in errors) {
                    $('#' + key + '-error').text(errors[key]);
                }
                button.text(originalCaption).removeAttr('disabled');
            },
        });
    });

    $('#staging-terminal, #terminal-for-empty').select2({
        theme: 'bootstrap4',
        placeholder: "Выберите терминал",
    }).on('select2:select', function (e) {
        let selected = e.params.data;
        let lat = parseFloat(selected.element.dataset.lat);
        let lng = parseFloat(selected.element.dataset.lng);

        addPlacemark(lat, lng, $(this).attr('data-sn'));
    });

    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });

    selectAddress.select2({
        theme: 'bootstrap4',
        placeholder: "Введите адрес",
        minimumInputLength: 2,
        ajax: {
            dataType: 'json',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: selectAddress.data('url'),
            data: function (params) {
                return {
                    search: params.term,
                };
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function (item, index) {
                        return {
                            text: item.value,
                            lat: item.lat,
                            lng: item.lng,
                            id: index
                        }
                    })
                };
            },
        },
        templateResult: function (data, container) {
            $(container).attr('data-lat', data.lat);
            $(container).attr('data-lng', data.lng);
            return data.text;
        },
        language: {
            inputTooShort: () => {
                return 'Введите часть адреса';
            },
            searching: () => {
                return "Поиск...";
            },
        },
    }).on('select2:select', function (e) {
        let el = $('#select-address');
        let selected = e.params.data;
        let lat = parseFloat(selected.lat);
        let lng = parseFloat(selected.lng);

        $(this).parent('.element-wrapper').find('.select2-selection__placeholder').text(selected.text);
        el.attr('data-lat', lat);
        el.attr('data-lng', lng);
        el.val(selected.text);

        addPlacemark(lat, lng, $(this).attr('data-sn'));
    });

    ymaps.ready(() => {
        yMap = new ymaps.Map('map', {
            center: [55.751574, 37.573856],
            zoom: 8,
            controls: []
        });

        yManager = new ymaps.ObjectManager({
            clusterize: false,
        });
    });

    $('#result').on('click', '#copy', () => {
        window.navigator.clipboard.writeText($('#copy').text());
    });

    /**
     * @param {Number} lat
     * @param {Number} lng
     * @param {Number} id Идентификатор объекта формы
     */
    function addPlacemark(lat, lng, id) {
        window['point' + id] = [lat, lng];

        yManager.objects.getById(id) && yManager.objects.remove([id]);

        yManager.add({
            "type": "Feature",
            "id": id,
            "geometry": {
                "type": "Point",
                "coordinates": [lat, lng]
            },
            "properties": {
                "iconContent": id
            }
        });

        yMap.panTo([lat, lng]);
        yMap.geoObjects.add(yManager);
    }

    /**
     * @param {Array} coordinates Координаты точек
     * @see https://yandex.ru/dev/maps/jsbox/2.1/multiroute_view_options/
     */
    function showRoute(coordinates) {
        yRoute && yMap.geoObjects.remove(yRoute);
        let multiRoute = new ymaps.multiRouter.MultiRoute({
            referencePoints: coordinates,
            params: {
                results: 1
            }
        }, {
            wayPointVisible: false,
            boundsAutoApply: true
        });
        yRoute = multiRoute;
        yMap.geoObjects.add(multiRoute);
    }
});
